<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav d-flex justify-content-center"
  >
    <template v-if="isVendor()">
      <template v-for="item in items">
        <component
          v-if="item.vendorAllow && item.vendorAllow == true"
          :is="resolveNavComponent(item)"
          :key="item.header || item.title"
          :item="item"
        />
      </template>
    </template>
    <template v-else>
      <template v-for="item in items">
        <component
          v-if="!item['onlyVendor']"
          :is="resolveNavComponent(item)"
          :key="item.header || item.title"
          :item="item"
        />
      </template>
    </template>
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from "../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue";
import HorizontalNavMenuHeaderGroup from "../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue";

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const resolveNavComponent = (item) => {
      if (item.children) return "horizontal-nav-menu-header-group";
      return "horizontal-nav-menu-header-link";
    };

    return {
      resolveNavComponent,
    };
  },
  methods: {
    isVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
