const developmentRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    vendorAllow: true,
  },

  {
    title: "Third Parties",
    route: "thirdpartyrisks",
    icon: "AlertOctagonIcon",
  },
  {
    title: "Questionnaires",
    route: "questionnaires",
    icon: "HelpCircleIcon",
  },
  {
    title: "Third Parties R.A",
    route: "thirdpartyrisksRA-assessments",
    icon: "AlertOctagonIcon",
  },

  {
    title: "Assessments",
    route: "vendorassessments",
    icon: "GitPullRequestIcon",
    vendorAllow: true,
    onlyVendor: true,
  },
];

const productionRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    vendorAllow: true,
  },

  {
    title: "Third Parties",
    route: "thirdpartyrisks",
    icon: "AlertOctagonIcon",
  },
  {
    title: "Questionnaires",
    route: "questionnaires",
    icon: "HelpCircleIcon",
  },
  {
    title: "Third Parties R.A",
    route: "thirdpartyrisksRA-assessments",
    icon: "AlertOctagonIcon",
  },

  {
    title: "Assessments",
    route: "vendorassessments",
    icon: "GitPullRequestIcon",
    vendorAllow: true,
    onlyVendor: true,
  },
];

export default process.env.VUE_APP_ENVIRON &&
process.env.VUE_APP_ENVIRON === "DEVELOPMENT"
  ? developmentRoutes
  : productionRoutes;
